.tree-select[data-v-c1870d0f] {
  width: 100%;
  display: inline-block;
  cursor: pointer;
}
.search-container[data-v-c1870d0f] {
  display: flex;
}
.search-container .search-button[data-v-c1870d0f] {
  margin-left: 8px;
}
.tree[data-v-c1870d0f] label {
  margin-bottom: 0px;
}
.tree-container[data-v-c1870d0f] {
  max-height: 500px;
  overflow-y: scroll;
}
.link-btn[data-v-c1870d0f] {
  display: inline;
}
.tree-input-color[data-v-c1870d0f] {
  color: #525252 !important;
}
