.bg[data-v-133bb8e1] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1020;
  background: #00000066;
}
.detail[data-v-133bb8e1] {
  flex: 0 var(--global-view-column-size);
  overflow: auto;
  order: 3;
}
.detail.detail-row[data-v-133bb8e1] {
  flex: 0 var(--global-view-row-size);
}
.detail.model[data-v-133bb8e1] {
  position: fixed;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  z-index: 1020;
  background: #FFF;
  border: 10px solid #FFF;
  border-width: 10px 0;
  border-radius: 10px;
}
.resize[data-v-133bb8e1] {
  flex: 0 1px;
  z-index: 7;
  box-sizing: content-box;
  background: #d9d9d9;
  background-clip: padding-box;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0;
  margin: -5px 0;
  cursor: row-resize;
  order: 2;
}
.resize[data-v-133bb8e1]:hover {
  border-color: #0000001a;
  transition: border-color 2s ease;
}
.resize.row[data-v-133bb8e1] {
  margin: 0 -5px;
  border-width: 0 5px;
  cursor: col-resize;
}
