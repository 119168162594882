.ag-theme-guigu[data-v-f2de3bf1] .ag-root-wrapper {
  overflow: visible;
  border: 1px solid var(--table-border-color);
  border-radius: var(--border-radius-md);
}
.ag-theme-guigu[data-v-f2de3bf1] .ag-root {
  border-radius: var(--border-radius-md);
}
.ag-theme-guigu[data-v-f2de3bf1] .ag-watermark {
  display: none;
}
.ag-theme-guigu[data-v-f2de3bf1] .ag-group-value {
  flex: 1;
}
.ag-theme-guigu[data-v-f2de3bf1] .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-guigu[data-v-f2de3bf1] .ag-layout-auto-height .ag-center-cols-container {
  min-height: var(--f2de3bf1-minHeight);
}
.pagination[data-v-f2de3bf1] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
