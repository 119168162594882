.guigu_date_editor_container[data-v-94183501] {
  display: flex;
  align-items: center;
  height: var(--input-height);
}
.guigu_date_editor_container[data-v-94183501] .el-date-editor.el-input {
  height: auto;
}
.guigu_date_editor_container[data-v-94183501] .el-input__suffix {
  position: absolute;
  right: 4px;
}
.guigu_date_editor_container[data-v-94183501] .el-input__wrapper {
  padding: 0;
}
.guigu_date_editor_container[data-v-94183501] .guigu_date_editor.el-date-editor.el-input {
  width: 100%;
}
.guigu_date_editor_container[data-v-94183501] .guigu_date_editor.el-date-editor.el-input .el-input__wrapper {
  box-shadow: none;
}
.grid-body .editable-row .guigu_date_editor_container[data-v-94183501] {
  height: 38px;
}
