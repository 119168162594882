.header[data-v-b2ea25b8] {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn-container[data-v-b2ea25b8] {
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
}
[data-v-b2ea25b8] .el-tabs__header {
  min-height: 40px;
  position: sticky;
  bottom: 0;
  z-index: 3;
}
[data-v-b2ea25b8] .el-tabs__content {
  padding: 0;
}
