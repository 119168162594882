.search-container[data-v-94a65612] {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  overflow: visible;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--padding-sm);
  min-height: 40px;
  box-sizing: content-box;
  gap: 8px;
}
.filter-input[data-v-94a65612] {
  width: 160px;
}
.tree-container[data-v-94a65612] {
  height: calc(100vh - 460px);
}
.tree-container .aggrid-container[data-v-94a65612] {
  height: calc(100vh - 520px);
  overflow: scroll;
}
.tree-container .aggrid-container .aggrid[data-v-94a65612] {
  height: 100%;
  width: 100%;
}
.tree-container .pagination[data-v-94a65612] {
  margin-top: 8px;
}
.create-btn[data-v-94a65612] {
  float: left;
}
.cus-link-btn[data-v-94a65612]:after {
  border-bottom: 0!important;
}
[data-v-94a65612] .tree-dialog .el-dialog__body {
  padding: 0 var(--el-dialog-padding-primary);
}
.tree-toolbar[data-v-94a65612] {
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
}
