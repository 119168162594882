.grid-footer[data-v-a79f6e53] {
  padding-top: 0;
}
#connection_table_remove[data-v-a79f6e53] {
  margin-left: var(--a79f6e53-removeBtnLeft);
}
#connection_table_add[data-v-a79f6e53] {
  margin-left: var(--a79f6e53-addBtnLeft);
}
#connection_table_link[data-v-a79f6e53] {
  margin-left: 12px;
}
.button-container[data-v-a79f6e53] {
  display: inline-block;
  margin-left: 12px;
}
.before-container[data-v-a79f6e53] {
  margin-left: 0;
}
.pagination_count[data-v-a79f6e53] {
  display: flex;
  float: right;
}
.pagination_count > div[data-v-a79f6e53] {
  margin-right: 8px;
}
